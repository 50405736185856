<template>
	<div class="txt-area grid">

		<div class="img-containner">
			<div class="left-img">
				<img class="cadeado" src="/assets/svgs/cadeado.svg" />
				<div class="img-txt">Canal de <span class="span-color">Privacidade</span></div>
			</div>
			<img class="personagem" src="/assets/svgs/personagens_5.svg" />
		</div>

		<div class="text-container">
			<p>A Auster leva a privacidade a sério.</p>
			<p> Qualquer dúvida ou solicitação relacionada à proteção de dados pessoais deverá ser encaminhada para
				nosso Canal de Privacidade: <a class="txt-link"
					href="mailto:lgpd@papauster.com.br">lgpd@papauster.com.br</a>.
			</p>
			<p class="">Trabalhamos conforme as melhores práticas de segurança para proteger os seus dados e
				respeitar a sua
				privacidade.</p>
			<p>O Canal de Privacidade faz parte de nossas ferramentas que reforçam o Sistema de Gestão de Governança
				de
				Proteção de Dados Pessoais da nossa organização, feito para você gerenciar seus dados pessoais junto
				à Auster,
				conforme a LGPD – Lei Geral de Proteção de Dados <a class="txt-link" target="_blank"
					href="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm">(Lei Federal N˚
					13.709/2018)</a>.
			</p>
			<p>
				Nosso principal objetivo é gerar uma relação de confiança com o titular dos dados pessoais, baseada
				na
				segurança, transparência e privacidade.
			</p>
			<p>Lembre-se: todos os dados fornecidos no Canal de Privacidade são revelados, de forma consensual, por
				você, o
				próprio titular do dado pessoal, preservando, consequentemente, a sua intimidade e privacidade e
				sempre
				respeitando a finalidade específica de atendimento da sua solicitação.
			</p>
			<p>
				É importante que você forneça os dados que sejam exigências legais e/ou necessários para análise,
				verificação
				e resolução da sua solicitação. Vale dizer, dados que não possuam relevância para a solicitação não
				serão
				requeridos nem devem ser registrados.
			</p>

			<h2>Perguntas Frequentes sobre</h2>
			<h2 class="text-color-yellow">Proteção de Dados Pessoais:</h2>

			<h3>O que é LGPD?</h3>
			<p class="text-font-size">É a Lei Geral de Proteção de Dados <a class="txt-link" target="_blank"
					href="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm">(Lei Federal N˚
					13.709/2018)</a> que dispõe sobre o
				tratamento de dados
				pessoais, definindo as hipóteses em que tais dados podem ser legitimamente utilizados por terceiros
				e
				estabelecendo mecanismos para proteger a privacidade dos titulares dos dados.</p>
			<h3>O que são dados pessoais protegidos pela LGPD?</h3>
			<p>A LGPD define dado pessoal como a informação relacionada a uma pessoa natural identificada ou
				identificável.
				Isto é, dados que permitam a identificação de um indivíduo. Não são considerados dados pessoais,
				para os
				efeitos da LGPD, os dados de pessoas jurídicas.</p>
			<h3>O que é tratamento de dados pessoais?</h3>
			<p>Nos termos da LGPD, tratamento de dados pessoais é toda operação realizada com dados pessoais, como
				as que se
				referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão,
				distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da
				informação,
				modificação, comunicação, transferência, difusão ou extração.</p>
			<p>Caso suas dúvidas não tenham sido respondidas, entre em contato conosco no nosso Canal de Privacidade
				<a class="txt-link" href="mailto:lgpd@papauster.com.br">lgpd@papauster.com.br</a>.
			</p>

		</div>


		<div class="buttons-bottom">
			<router-link to="/">
				<div class="button-icon buttons-space">
					<i class="fas fa-home button-icon__icon"></i>
					<a href="/" class="button-icon__button">Voltar a Home</a>
				</div>
			</router-link>
			<!-- <router-link to="/termos">
				<div class="button-icon buttons-space">
					<i class="fas fa-lock button-icon__icon"></i>
					<a href="#" class="button-icon__button">Políticas & Privacidade</a>
				</div>
			</router-link> -->
		</div>

	</div>
</template>

<script>

export default {
	name: "NotLogedPrivacyChanel",
	data() {
		console.log("🚀 ~ file: Contact.vue:26 ~ data ~ this.$route", this.$route)
		return {};
	},
	methods: {},
	mounted() {
		document.getElementsByClassName('header')[0].style.visibility = 'hidden';
		document.getElementsByClassName('header')[0].style.height = '0px';
		document.getElementsByClassName('header')[0].style.padding = '0px';
		//rodapé
		document.getElementsByClassName('footer')[0].style.visibility = 'hidden';
		document.getElementsByClassName('footer')[0].style.height = '0px';
		document.getElementsByClassName('footer')[0].style.padding = '0px';
		//icone de libras
		document.getElementById('vlibras').style.visibility = 'hidden';
		document.querySelector(".padding-bottom").style.padding = 0;
	}


};
</script>

<style lang="scss" scoped>
@import "../../scss/variables";
.img-containner {
	margin-bottom: 7vh;
	margin-top: 2vh;
	position: relative;

	@media (max-width: 768px) {
		font-size: 0.7rem;
		margin-bottom: 3vh;
		margin-top: 0;
	}
}

.left-img {
	display: flex;
	align-items: center;

	transform: translateY(25%);

	@media (max-width: 586px) {
		transform: translate(-5%, 25%);
	}
}

.cadeado {
	width: 10vh;

	@media (max-width: 586px) {
		width: 7vh;
	}
}

.img-txt {
	font-size: 2.5em;
}

.span-color {
	color: $color-primary-light;
}

.personagem {
	position: absolute;
	width: 22%;
	top: 0;
	right: 0;

	@media (max-width: 586px) {
		width: 25%;
		transform: translateY(20%);
	}
}

.text-container>p {
	font-size: 1.5rem !important;
	font-family: 'Open Sans', sans-serif;
	font-size: 100rem;
	box-sizing: border-box;
	color: $color-dark;
	font-size: medium;
	line-height: 24px;
	text-align: justify;
	margin-bottom: 3vh;
}

.text-container>h2 {
	font-weight: bold;
	text-align: center;
	margin: auto;

	@media (max-width: 768px) {
		font-size: 2.3rem !important;
	}
}

.text-color-yellow {
	color: $color-secondary;
}

.text-container>h3 {
	font-weight: bold;
	font-style: italic;
	font-size: 1.6rem !important;
	margin-bottom: 2vh;

	@media (max-width: 768px) {
		font-size: 2rem !important;
	}
}

.txt-link {
	font-weight: bold !important;
	color: $color-dark !important;
}

.txt-link:hover {
	cursor: pointer;
	text-decoration: underline;
}

.email-link:hover {
	color: $color-dark !important;
}



.grid {
	margin-top: 3vh;
	display: grid;
	justify-content: center;
	align-content: center;
	grid-template-columns: 78rem;
	/* margin-top: -15rem; */
	z-index: 999;

	@media (max-width: 1100px) {
		// margin-top: -12rem;
		grid-template-columns: 85%;
	}
}

.buttons-bottom {
	margin-top: 8vh;
	display: flex;
	justify-content: center;

	@media (max-width: 768px) {
		display: block;
		text-align: center;
	}
}

.buttons-space {
	margin-right: 1vw;
	margin-left: 1vw;
}

.button-icon {
	position: relative;
	justify-self: center;
	margin-bottom: 5rem;

	&__icon {
		position: absolute;
		z-index: 999;
		color: $color-white;
		font-size: 1.2rem;
		left: 3rem;
		top: 4px;
	}

	&__button {
		text-transform: uppercase;
		font-weight: normal;
		background-image: linear-gradient(to right, #307278, #26989e);
		color: #fff;
		font-size: 1.4rem;
		padding: 1rem 2rem;
		padding-left: 5rem;
		padding-right: 3rem;
		border-radius: 4.2rem;
		margin-top: 3.3rem;
		position: relative;
		margin-left: 0.5rem;
		outline: 0;

		&::before {
			position: absolute;
			content: "";
			width: 103%;
			height: 115%;
			left: -1.5%;
			top: -7.5%;
			border-radius: 4.2rem;
			background-color: transparent;
			border: 1px solid #307278;
		}
	}
}
</style>

