



































import { Vue, Component } from "vue-property-decorator";
import Navbar from "../../components/Navbar.vue";
import FooterPage from "../../components/FooterPage.vue";
import AusterButton from "../../components/AusterButton.vue";
import FaqQuestionItem from "../../components/FaqQuestions/FaqQuestionItem.vue";

import faqQuestionsPsychologist from "../../components/FaqQuestions/faqQuestionsPsychologist";
import faqQuestionsPacient from "../../components/FaqQuestions/faqQuestionsPacient";
import faqQuestionsOthers from "../../components/FaqQuestions/faqQuestionsOthers";
import faqQuestionsGeneral from '../../components/FaqQuestions/faqQuestionGeneral';

@Component({
  components: {
    Navbar,
    FooterPage,
    AusterButton,
    FaqQuestionItem,
  },
})
export default class Faq extends Vue {
  questionsPsychologist = faqQuestionsPsychologist;
  questionsPacient = faqQuestionsPacient;
  questionsOther = faqQuestionsOthers;
  questionsGeneral = faqQuestionsGeneral;

  faqContent: string = "Paciente";

  setPacient() {
    this.faqContent = "Paciente";
  }

  setPyschologist() {
    this.faqContent = "Psicólogo";
  }

  setOther() {
    this.faqContent = "Outros";
  }

  setGeneral() {
    this.faqContent = "Geral";
  }
}
