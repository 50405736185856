









import { Vue, Component } from "vue-property-decorator";
import Navbar from "../../components/Navbar.vue";
import FooterPage from "../../components/FooterPage.vue";
import SearchPsychologistComponent from "../../components/SearchPsychologistComponent.vue";
import LoginModal from "@/components/LoginModal.vue";

@Component({
  components: {
    Navbar,
    FooterPage,
    SearchPsychologistComponent,
    LoginModal
  },
})
export default class SearchPsychologist extends Vue {
  logado: boolean = false;

  async mounted() {
    console.log('active user');
    const user = JSON.parse(localStorage.getItem("activeUser"));
    console.log('active user', user);
    this.logado = user !== null && user.id > 0;

    if (!this.logado) {
      //this.logado = false;
      this.$vs.notification({
        color: "danger",
        title: ``,
        text: "Faça o login na plataforma.",
        type: "alert",
        //acceptText: "Ok",
        //cancelText: "Cancelar",
        //accept: () => {

        //},
        //cancel: () => resolve(false),
      });
      (this.$refs.loginModal as any).showLogin();
    }


  }
}
