export default [
  {
    question : "Posso mudar de especialista?",
    answer : "Pode. Caso deseje trocar de profissional, você poderá entrar em contato com a nossa equipe no e-mail faleconosco@fiqueokonline.com.br escolher um novo profissional que combine mais com você."
  },
  {
    question : "Sou um especialista, posso me cadastrar? O que devo fazer?",
    answer : "Nossos profissionais são todos cadastrados e homologados antes de iniciar os atendimentos pela nossa plataforma, mande um e-mail para redecredenciada@papauster.com.br e solicite nossa ficha de cadastro."
  },
]
