export default [
    {
        question: "Fique Ok Online:",
        answer: "O “Fique Ok Online” é um Portal de Terapia Psicológica e Orientação Social 100% online, feito através de vídeo chamadas entre os clientes e psicólogos/assistente sociais cadastrados pela Auster. Além das regras e condições para utilização, esta Política de Privacidade explica o seu funcionamento."
    },
    {
        question: "Cadastro e informações pessoais cliente e psicólogo/orientadores sociais:",
        answer: " • Acesso ao portal: <br>Ao acessar o \"Fique Ok Online\", o cliente/psicólogo/assistente social utiliza os dados de Login, referente ao que foi cadastrado ao criar seu perfil: <br><br> • Cliente: <br> 1. Nome Completo; <br> 2. Telefone; <br> 3. CPF; <br> 4. Data de Nascimento; <br> 5. Gênero; <br> 6. E-mail; <br> 7. Senha; <br><br> • Psicólogo/Assistente Social: <br> 1. Nome Completo; <br> 2. Telefone; <br> 3. CRP/CRESS; <br> 4. Gênero <br> 5. E-mail <br> 6. Senha; <br> 7. Descrição; <br> 8. Especialidades; <br> 9. Agenda; <br> 10. Formação Acadêmica. <br><br>Para fins desta Política de Privacidade, \"informações pessoais\" significam informações identificáveis de um Cliente e Psicólogo/Assistente Social, que são coletadas de 02 maneiras: <br><br> 1. Quando você faz Login na Plataforma. <br><br> 2. Quando você agenda uma consulta."
    },
    {
        question: "Local de armazenamento dos dados",
        answer: "Mantemos os dados em território Nacional, todos os nossos Servidores ficam no Brasil."
    },
    {
        question: "Posso cancelar minha consulta?",
        answer: "Sim, basta acessar seu perfil na plataforma. Depois clique no menu lateral esquerdo e selecione a opção de \"Agenda\": <br><br> • Selecione a consulta que deseja cancelar;<br> • Clique em \"Ver detalhes\";<br> • Cancelar consulta;<br> • Justifique o motivo;<br> • Pronto, sua consulta foi cancelada e seu voucher voltará para seu perfil. Podendo ser reutilizado posteriormente;<br><br>Atenção: cancelamentos serão permitidos com prazo mínimo de 24h de antecedência!"
    },
    {
        question: "Atendimento pelo WhatsApp e Teams",
        answer: "Atendimento online pode ser realizado por chamada de video via WhatsApp ou Teams, conforme data e horário escolhidos.<br>Seu voucher será debitado após a conclusão da consulta.<br><br>O profissional entrará em contato por meio do telefone fornecido no cadastro, todo processo é protocolado com notificações enviadas no e-mail."
    },
]
