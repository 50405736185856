export default [
  {
    question : "Estou com problemas para realizar minha consulta",
    answer : "Verifique se sua webcam está ligada ou se seu smartphone está com a câmera habilitada;\n  1. Verifique permissões do seu navegador (deixar opção habilitada autorizando o uso da câmera + microfone + flash + som); 2. Utilize sempre a versão mais atual do seu Navegador; 3. Verifique se sua webcam está funcionando corretamente ou está ligada em uma porta de alimentação usb corretamente."
  },
  {
    question : "O Fique Ok Online é seguro? Vocês garantem minha privacidade?",
    answer : "As consultas não são gravadas e são sigilosas. As informações do site são protegidas por criptografia ponta a ponta - ou seja, seus dados e o que for conversado na consulta ficarão entre você e seu especialista."
  },
  {
    question : "A plataforma é cadastrada junto ao Conselho Federal de Psicologia?",
    answer : "A partir da resolução de novembro de 2018 do Conselho Federal de Psicologia (CFP - 11/2018) os atendimentos online foram regulamentados sem limite de consultas, ficando a cargo dos especialistas se cadastrarem junto ao órgão E-psi para que sejam aprovados os seus serviços online. Todos os psicólogos do Fique Ok Online são cadastrados."
  },
]
