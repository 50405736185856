












import {
  Vue, Prop, Component,
} from "vue-property-decorator";

@Component({})
export default class FaqQuestionItem extends Vue {
  @Prop()
  question: string;

  @Prop()
  answer: string;


  arrowIcon: string = "keyboard_arrow_down"
  isOpen: boolean = false

  openCloseItem() {
    if (this.arrowIcon === "keyboard_arrow_down") {
      this.arrowIcon = "keyboard_arrow_up"
      this.isOpen = true
    } else {
      this.arrowIcon = "keyboard_arrow_down"
      this.isOpen = false
    }
  }
}



