














import { Vue, Component } from "vue-property-decorator";
import Navbar from "../../components/Navbar.vue";
import FooterPage from "../../components/FooterPage.vue";

@Component({
  components: {
    Navbar,
    FooterPage,
  },
})
export default class Contact extends Vue {}
