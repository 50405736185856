export default [
  {
    question : "As consultas possuem valores?",
    answer : "Não é preciso realizar nenhum tipo de pagamento, apenas utilização do Voucher Empresarial."
  },
  {
    question : "Como funciona a utilização do Voucher?",
    answer : "Tudo muito rápido e prático:<br> • Cliente Seleciona o Especialista de sua preferência;<br> • Realiza o cadastro na Plataforma: <br> • Agenda Data/Horário e tipo de consulta conforme calendário;<br> • Insere o código do Voucher Empresarial; <br> • O especialista recebe o comprovante de agendamento;<br> • Todo processo é protocolado, através de e-mails e mensagens no WhatsApp;<br> • Feito todo processo, basta entrar na plataforma Fique Ok / WhatsApp / Teams (conforme seu agendamento), na data e horário agendados."
  },
  {
    question : "Problemas ao inserir Voucher:",
    answer : "Mande um e-mail para faleconosco@fiqueokonline.com.br, informando seus dados de acesso e o problema."
  },
]
